/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif; /* Change Montserrat to Roboto */
  background: url('img.png') no-repeat center center fixed;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: -1;
}

#root {
  flex: 1;
  overflow-y: auto;
  position: relative;
  z-index: 1;
}

.app {
  text-align: center;
}

.projects {
  padding: 50px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

