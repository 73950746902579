/* src/Header.css */
.header {
    color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 60px;
    padding-right: 60px;
    text-align: left;
    //background: rgba(0, 0, 0, 0.6); /* Use alpha channel for transparency */
}

.profile-text-container {
    display: flex;
    align-items: center;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px; /* Adjust margin as needed */
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.header-logo {
    font-size: 1.2em;
}

.header-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.header-nav li {
    margin-left: 10px;
}

.header-nav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.0em;
    transition: color 0.3s ease-in-out;
}

.header-nav img {
    width: 18px;
    height: 18px;
    margin-left: 2px;
}
